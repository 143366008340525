import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Krūmroze",
            image: "https://i.imgur.com/GNaV8mUb.jpg",
            native: "Āzija",
            description: "Viens no daudzajiem rožu veidiem. Daudzi mazāki ziedi uz viena stublāja, lieliski piešķir krāsu ziedu kompozīcijām. Ar mazo izmēru tās lieliski papildina lielākus ziedus. To krūmi var sasniegt pat divu metru augstumu.",
            care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Anemone",
            image: "https://i.imgur.com/0HWOWerb.jpg",
            native: "Vidusjūras apgabals",
            description: "Kaprīzas, bet ļoti izteiksmīgas. Pieminētas pat sengrieķu stāstos un citās valstīs tiek sauktas par vēja puķēm. Līdzīgi kā tulpes, tās turpina augt vāzē, tāpēc ap tām jāatstāj brīva vieta. Anemones ir skaistas, bet to mūžs vāzē nav ilgs 3-6 dienas.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Īpaši sargi no saules un siltuma avotiem. Neturi blakus augļiem. ",
        },
        
        {
            title: "Pitosporas zari",
            image: "https://i.imgur.com/vOdvIqyb.jpg",
            native: "Austrālija",
            description: "Šajos zaros ir kas japānisks. Tos mēdz dēvēt par Pitto. Tie lieliski piedod apjomu ziedu kompozīcijām un bieži tiek izmantoti, kā atbalsts, mazāk stingriem ziediem. Arī šajā kompozīcijā uz tiem atbalstījām sniega bumbas.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },
        {
            title: "Pildītā roze",
            image: "https://i.imgur.com/Gcsyn09b.png",
            native: "Āzija",
            description: "Īstas karalienes. Šo rožu ziedi ir vieni lielākajiem. Pasaulē lielākā rozes zieda diametra rekords ir 84cm. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
            care: "Ik pēc 2 dienām, kad maini ūdeni vāzē, nogriez tām kātiņus aptuveni 2 cm no apakšas, 45 grādu leņķī, lai zieds varētu uzsūkt vajadzīgo ūdeni un tajā esošās barības vielas. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Frēzija",
            image: "https://i.imgur.com/m9M0CpJb.jpg",
            native: "Dienvidāfrika",
            description: "Arī saukta par balerinām. Salda un maiga smarža un unikalitātes ziņā frēzijām līdzīgi ziedi nav atrodami. Tās izceļas ar to, ka ziedi aug paralēli zemei, spītējot gravitācijai.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Neturi blakus augļiem.",
        },
        {
            title: "Sniega bumbas",
            image: "https://i.imgur.com/SDC8Dxzb.jpg",
            native: "Centrāleiropa",
            description: "Plaši izplatīts dārzu krūms Vācijā un Francijā. Pēc ziediem pavasarī, tās vēlāk dod ogas. Sauktas arī kā irbenes.",
            care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām.",
        },
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">

                    <h2 class="title is-3" style={{ color: '#eee' }}>Instrukcija</h2>

                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/a985f034-9783-47cf-8a36-3fedaec6b6b7/playlist.m3u8"
                        captions="/titles/003.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Mazliet klasikas</h2>

                            <p>Šoreiz veidosim klasisku apaļu ziedu kompozīciju, kur dominēs zaļi, balti un maigi persiku krāsas toņi. Kompozīcijas izveidošana mūsuprāt ir vienkāršāka kā iepriekšējās reizes, bet ir nepieciešams zināt nedaudz “trikus”. Vāze, vislabāk derēs ar šaurāku kakliņu, kas ir 12 - 15cm plats.
Ziedus nepieciešams kārtot pēc zināmas secības, tad nu aiziet: </p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa* uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 3cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                                <li>Pirmais triks, ziedu stublājiem jābūt vienā garumā, lai spētu izveidot apaļu kompozīciju, to garums ir atkarīgs no Tavas vāzes augstuma</li>
                                <li>Sāc ar zaļi baltajiem zariņiem (Pittosporum), kur tos pavisam vienkārši izkārto vāzē - šie zariņi ir kā skelets kompozīcijai, kas dos apjomu un noturēs formu </li>
                                <li>Tālāk liec stap zariem Viburnum Roseum jeb sniega bumbas. Triks ir izmantot zaļi baltos zariņus, kā atbalstus, lai uz tiem uzliktu smagos ziedus, kā arī tie ļauj simetriski izkārtot ziedu bumbas kompozīcijā. Grūtākais ir padarīts</li>
                                <li>Izkārto vāzē pildītās, lielās Rozes, Krūmrozes un smaržīgās Gerberas </li>
                                <li>Visbeidzot kā pēdējās liec vāzē Anemones, tie ir ļoti trausli ziedi, tāpēc tās tiks piegādās vēl pumpuros, bet atrodoties istabas temperatūrā tās pavisam neilgā laikā atvērsies</li>
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Messenger čatā, loga stūrī!</p>

                            <p>* Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/A7D2uKS.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja to ūdeni mainīsi reizi 2-3 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni ar ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
